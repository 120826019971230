<template>
  <div class="result">
    <div class="result_whiteboard">
      <!-- header 标题 -->
      <div class="result_whiteboard_title">
        <div class="sanjiao_left"></div>
        <div class="sanjiao_right"></div>
        <div class="trapezoidal_big_top"></div>
        <p>灯塔考研高分作文模板</p>
        <div class="trapezoidal_big_bottom"></div>
      </div>
      <!-- con 内容 -->
      <div class="result_whiteboard_text">
        <p>您购买的作文模板为真</p>
        <p>祝同学成功上岸！</p>
      </div>
      <div class="result_whiteboard_recommend" @click="cashBack">
        <p>推荐同学返现</p>
      </div>
      <div class="result_whiteboard_rules">
        <p @click="viewRules">查看规则</p>
      </div>
      <!-- 弹窗 背景阴影 -->
      <div class="result_whiteboard_shadow" v-if="recommend_popup || rules_popup" @click="closePopup"></div>
      <!-- 推荐返现 弹窗 -->
      <!-- style="width: 100%; height: 100%" -->
      <div class="result_whiteboard_recommend_popup" v-if="recommend_popup">
        <!-- <canvas
          class="canvas"
          style="width: 100%; height: 100%"
          canvas-id="mycanvas"
          id="canvas"
        ></canvas>
        <div @click="save" class="result_whiteboard_recommend_save">
          保存海报
        </div> -->
        <img width="100%" height="100%" :src="canvasBgUrl" alt="" />
      </div>
      <!-- 查看规则 弹窗 -->
      <div class="result_whiteboard_rules_popup" v-if="rules_popup">
        <div class="rules_popup_ttitle">查看规则</div>
        <div class="rules_popup_cons">
          <div class="rules_popup_con">1.点击“推荐同学返现”按钮生成专属分享海报。</div>
          <div class="rules_popup_con">2.将专属海报发送给研友，邀请研友购买作文模板。</div>
          <div class="rules_popup_con">3.研友成功购买作文模板，即可获得推荐奖励。</div>
        </div>
      </div>
      <!-- banner -->
      <div class="result_whiteboard_banner" @click="toTaobao">
        <img src="../assets/img/index-banner.jpg" alt="" class="banner-img">
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "Result",
  components: {},
  data() {
    return {
      recommend_popup: false, // 推荐同学返现弹窗 显示隐藏
      rules_popup: false, // 查看规则弹窗 显示隐藏
      canvasBgUrl: "",
      onlyCode: "",
      // onlyCode: "DTZW3vuW447Fm9q5137w",
      isWeixin: false, //是否在微信环境
      wxAppId: "wx6f103f7b5b4c187b", //appid
      url: "https://rank.yoyolooming.com/result", //重定向返回地址
    };
  },
  created() {
    if (this.$route.query.onlyCode) {
      this.onlyCode = this.$route.query.onlyCode;
    }
  },
  mounted() {
    // 授权回来
    if (
      this.urlParams("code") &&
      localStorage.getItem("code") != this.urlParams("code")
    ) {
      console.log(11111, "ok");
      this.getUserInfo(this.urlParams("code"));
    }
  },
  methods: {
    // 点击 推荐同学返现
    async cashBack() {
      var ua = navigator.userAgent.toLowerCase();
      var isWeixin = ua.indexOf("micromessenger") !== -1; // 是否在微信浏览器内
      this.isWeixin = isWeixin;
      // 授权
      if (!this.isWeixin || !this.urlParams("code")) {
        // if (!this.isWeixin) {
        this.getLogin();
      } else {
        await this.$axios
          .get(
            "https://manage.yoyolooming.com/api/template/verify/generatePoster?onlyCode=" +
            this.onlyCode
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.errorCode == 200) {
              this.canvasBgUrl = res.data.data;
            }
            if (res.data.errorCode != 200) {
              Toast.fail(res.data.msg);
              this.recommend_popup = false;
            }
          });
        this.recommend_popup = true;
        // this.createNewImg();
      }
    },
    // 授权登录
    getLogin() {
      let url = this.url + "?onlyCode=" + this.onlyCode;
      let str = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.wxAppId}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
      window.location.href = str;
    },
    // 获取url参数
    urlParams(name) {
      let str = window.location.href;
      let params = str.substr(str.lastIndexOf("?") + 1).split("&");
      for (let i = 0; i < params.length; i++) {
        console.log(params);
        let param = params[i];
        let key = param.split("=")[0];
        let value = param.split("=")[1];
        console.log(key, name, 222);
        if (key === name) {
          console.log(value, 33333);
          return value;
        }
      }
    },
    // 获取用户信息
    async getUserInfo(code) {
      localStorage.setItem("code", code);
      let params = { code: code, onlyCode: this.onlyCode };
      console.log("调取接口 获取信息", params);
      await this.$axios
        .post(
          "https://manage.yoyolooming.com/api/template/verify/wxpay/getAccessToken",
          params
        )
        .then((res) => {
          if (res.data) {
            console.log(res, "122222222231232132143235465442res");
          }
        });
    },
    // 点击 查看规则
    viewRules() {
      this.rules_popup = true;
    },
    // canvas 海报制图
    createNewImg() {
      this.$nextTick(() => {
        let canvas = document.querySelector("canvas");
        let ctx = canvas.getContext("2d");
        // 解决图片模糊 以及 图片保存 bug
        let dpr = window.devicePixelRatio;
        let { width: cssWidth, height: cssHeight } =
          canvas.getBoundingClientRect();
        canvas.width = dpr * cssWidth;
        canvas.height = dpr * cssHeight;
        ctx.scale(dpr, dpr);
        // 绘制背景
        let img_bg = new Image();
        img_bg.src = this.canvasBgUrl;
        img_bg.setAttribute("crossOrigin", "Anonymous"); // 解决图片渲染bug
        img_bg.onload = () => {
          ctx.drawImage(img_bg, 0, 0, cssWidth, cssHeight);
        };
      });
    },
    // 点击 保存海报
    save() {
      let canvas = document.querySelector("canvas");
      var imgDataSrc = canvas.toDataURL("image/png");
      var fixtype = function (type) {
        type = type.toLocaleLowerCase().replace(/jpg/i, "jpeg");
        var r = type.match(/png|jpeg|bmp|gif/)[0];
        return "image/" + r;
      };
      imgDataSrc = imgDataSrc.replace(fixtype("jpg"), "image/octet-stream");
      var saveFile = function (data, filename) {
        var link = document.createElement("a");
        link.href = data;
        link.download = filename;
        var event = document.createEvent("MouseEvents");
        event.initMouseEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        link.dispatchEvent(event);
      };
      var filename = new Date().toLocaleDateString() + "." + "jpg";
      saveFile(imgDataSrc, filename);
    },
    // 点击关闭 推荐同学返现 查看规则 弹窗
    closePopup() {
      this.recommend_popup = false;
      this.rules_popup = false;
    },
    // 点击 底部banner
    toTaobao() {
      window.location.href = "https://mp.weixin.qq.com/s/6mEdqM17FrLrnuCMYoY15g";
    },
  },
};
</script>
<style lang="less">
/* 背景 */
.result {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/img/result_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  /* 白板部分 */
  .result_whiteboard {
    width: 92%;
    height: 64vh;
    border-radius: 0.64rem;
    background: #ffffff;
    margin: auto;
    margin-top: 32vh;
    overflow: hidden;

    /* header 标题部分 */
    .result_whiteboard_title {
      font-weight: bold;
      color: #a24b1e;
      font-size: 0.8rem;
      letter-spacing: 0.1rem;
      line-height: 1.6rem;
      text-align: center;
      margin-top: 1.12rem;
      position: relative;

      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .sanjiao_left {
        width: 1.3333rem;
        height: 1.3333rem;
        border-left: 0.08rem solid #ecba60;
        border-top: 0.08rem solid #ecba60;
        position: relative;
        transform: rotate(-45deg);
        position: absolute;
        left: 2.1067rem;
        top: 0.1667rem;
      }

      .sanjiao_right {
        width: 1.3333rem;
        height: 1.3333rem;
        border-right: 0.08rem solid #ecba60;
        border-bottom: 0.08rem solid #ecba60;
        position: relative;
        transform: rotate(-45deg);
        position: absolute;
        right: 2.1067rem;
        top: 0.1667rem;
      }

      .trapezoidal_big_top {
        width: 12.3733rem;
        height: 0;
        border-bottom: 0.88rem solid #ecba60;
        border-left: 1.36rem solid transparent;
        border-right: 1.36rem solid transparent;
        margin: auto;
      }

      .trapezoidal_big_bottom {
        width: 12.3733rem;
        height: 0;
        border-top: 0.88rem solid #ecba60;
        border-left: 1.36rem solid transparent;
        border-right: 1.36rem solid transparent;
        margin: auto;
      }
    }

    /* con 内容部分 */
    .result_whiteboard_text {
      margin-top: 2.4rem;

      p {
        color: #941302;
        font-weight: bold;
        font-size: 1.0667rem;

        text-align: center;
      }

      p:nth-child(2) {
        margin-top: 0.7733rem;
        margin-bottom: 3.2267rem;
      }
    }

    .result_whiteboard_recommend {
      width: 46%;
      height: 2.1333rem;
      line-height: 2.1333rem;
      border-radius: 1.0667rem;
      color: #ffffff;
      font-size: 0.8533rem;
      font-weight: bold;
      background-color: #f46d50;
      text-align: center;
      margin: auto;
    }

    .result_whiteboard_rules {
      color: #666666;
      font-weight: bold;
      width: 5.3333rem;
      margin: auto;
      font-size: 0.6933rem;
      text-align: center;
      margin-top: 0.8rem;
    }

    /* banner */
    .result_whiteboard_banner {
      width: 84%;
      height: 6.4667rem;
      position: fixed;
      left: 50%;
      transform: translate(-50%);
      bottom: 2.5rem;
      background: #ffecec;
      border-radius: 0.7733rem;

      .banner-img {
        width: 100%;
        height: 100%;
        border-radius: 0.7733rem;
      }
    }

    /* 推荐同学返现 popup */
    .result_whiteboard_recommend_popup {
      width: 14.6552rem;
      height: 31.7333rem;
      /* border: 1px solid red; */
      position: absolute;
      top: 8%;
      left: 50%;
      transform: translate(-50%);
      z-index: 999;

      .result_whiteboard_recommend_save {
        width: 6.4rem;
        height: 2.1333rem;
        line-height: 2.1333rem;
        background-color: #ecba60;
        color: #ffffff;
        font-size: 0.8533rem;
        border-radius: 1.0667rem;
        text-align: center;
        margin: 1.6rem auto;
      }
    }

    .result_whiteboard_shadow {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0%;
      left: 0%;
      background-color: #000000;
      opacity: 0.8;
      z-index: 999;
    }

    /* 查看规则 popup */
    .result_whiteboard_rules_popup {
      width: 19.625rem;
      height: 14.125rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url(../assets/img/rule-prop.png) no-repeat;
      background-size: contain;
      z-index: 999;

      .rules_popup_ttitle {
        font-size: 1.375rem;
        color: #fff;
        font-weight: bold;
        position: absolute;
        top: .3125rem;
        left: 50%;
        transform: translate(-50%);
      }

      .rules_popup_cons {
        width: 17rem;
        font-size: .9375rem;
        color: #6D6B6B;
        font-weight: bold;
        position: absolute;
        top: 3.9rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
