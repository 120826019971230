<template>
    <div class="home">
      <div class="home_whiteboard">
        <!-- header 标题 -->
        <div class="home_whiteboard_header wrapper">
          <div class="home_whiteboard_header_title">
            <p>模板唯一编号</p>
          </div>
          <!-- <div class="home_whiteboard_header_number">
            <p>累计扫码查询：{{ frequency }}次</p>
          </div> -->
        </div>
        <!-- con 内容 -->
        <div class="home_whiteboard_con wrapper">
          <div class="home_whiteboard_con_fake">
            <input type="text" :placeholder="activeCode" disabled="disabled" />
          </div>
          <div class="home_whiteboard_con_tel" :class="inputBorder ? 'home_whiteboard_con_tel_add' : ''">
            <input type="number" placeholder="请输入手机号" oninput="value=value.replace(/[^\d]/g,'')" maxlength="11"
              :disabled="telDisabled" v-model="telValue" @blur="telbBlur" @focus="telFocus" />
          </div>
          <div class="home_whiteboard_con_code">
            <div class="home_whiteboard_con_inputcode">
              <input type="number" placeholder="请输入验证码" v-model="code" />
            </div>
            <div class="home_whiteboard_con_obtaincode" @click="obtainCode">
              <button :disabled="codeDisabled">{{ btnmsg }}</button>
            </div>
          </div>
        </div>
        <!-- button -->
        <div class="home_whiteboard_query">
          <button @click="handleActive">立即激活</button>
        </div>
        <!-- 底部 banner -->
        <div class="home_whiteboard_banner" @click="toTaobao" :style="!codeShow ? '' : 'margin-top:1.6rem'">
          <!-- <a href="https://t.asczwa.com/taobao?backurl=https://m.tb.cn/h.UcxfC9U"
            >淘宝</a
          > -->
          <a href="https://mp.weixin.qq.com/s/6mEdqM17FrLrnuCMYoY15g">
            <img src="../assets/img/index-banner.jpg" class="banner-img">
          </a>
        </div>
        <!-- 假用户警告 -->
        <div class="fakeAlert" v-if="fakeAlertShow">
          <div class="home_whiteboard_shadow"></div>
          <div class="home_whiteboard_fakeAlert">
            <div class="p">你已成功激活<br>灯塔考研英语作文模板</div>
            <p>请扫描下方二维码关注公众号</p>
            <p>领取作文模板配套资料</p>
            <img src="../assets/img/qrcode.jpg" alt="">
          </div>
        </div>
      </div>
      <!-- loading 动图 -->
      <div class="loading" v-if="loadingShow">
        <div class="loadingBox">
          <img width="100%" height="100%" src="../assets/img/loading.gif" alt="" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Toast } from "vant";
  export default {
    name: "Home",
    components: {},
    data() {
      return {
        activeCode: "", // 激活码
        version: "1", //检测扫码次数
        telValue: "", // 电话号码 input 框数据绑定
        code: "", // 验证码
        btnmsg: "获取验证码", // 获取验证码
        seconds: "60", // 重新获取验证码倒计时
        codeData: {}, // 手机号获取数据
        telTail: "", // 手机尾号
        msg: "", // 接口返回信息
        inputBorder: false, // 输入框聚焦添加边框
        fakeAlertShow: false, // 假用户警告弹窗
        telDisabled: false, // 电话号码 input 框不可用
        codeDisabled: false, // 获取验证码 button 不可用
        codeShow: false, // 控制 验证码 灯塔logo 的显示隐藏
        loadingShow: false, // loading加载
        targetUrl:'https://manage.yoyolooming.com'
      };
    },
    mounted() {
      this.activeCode = this.getQueryString('active');
      localStorage.setItem("activeCode", this.activeCode);
      this.checkActive()
    },
    methods: {
      checkActive(){
        let params = {
          active:this.activeCode
        }
        this.$axios.post("https://manage.yoyolooming.com/api/template/verify/checkActive",
              params
            ).then((res) => {
              if(res.data.success){
                if(res.data.data){
                  this.$router.push({
                    path: "/",
                    query: {
                      onlyCode: res.data.data,
                    },
                  });
                }
              }else{
                Toast.fail(res.data.msg);
              }
            });
      },
      getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return null;
      },
      handleActive(){
          let params = {
          active:this.activeCode,
          phone:this.telValue
        }
        this.$axios.post("https://manage.yoyolooming.com/api/template/verify/activeThenUse",
              params
            ).then((res) => {
              if(res.data.success){
                this.fakeAlertShow = true
              }else{
                Toast.fail(res.data.msg);
              }
            });
        },
      // tel 输入框失焦
      telbBlur() { },
      // tel 输入框聚焦
      telFocus() {
        // this.inputBorder = true;
      },
      // 点击 获取验证码
      async obtainCode() {
        // 开启倒计时
        this.codeDisabled = true; // 按钮不可用
        this.btnmsg = "重新发送" + this.seconds + "s";
        // 开启定时任务
        var timer = setInterval(() => {
          if (this.seconds > 0) {
            this.seconds--;
            this.btnmsg = "重新发送" + this.seconds + "s";
          } else {
            clearInterval(timer); //停止倒计时
            this.codeDisabled = false;
            this.seconds = 60;
            this.btnmsg = "再次发送验证码";
          }
        }, 1000);
        // 获取手机验证码
        let a = { phoneNum: this.telValue };
        await this.$axios
          .post(
            "https://manage.yoyolooming.com/api/template/phone/sendVerificationCode",
            a
          )
          .then((res) => {
            if ((res.data.errorCode = 200)) {
              Toast("发送成功");
            } else {
              Toast(res.data.msg);
            }
          });
      },
      // 点击 底部banner
      toTaobao() {
        // window.location.href = "https://m.tb.cn/h.UcxfC9U";
        // window.location.href = "taobao://";
      },
    },
  };
  </script>
  <style lang="less" scoped>
  /* 背景 */
  .home {
    width: 100%;
    height: 100vh;
    background-image: url("../assets/img/active.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
  
    /* 白板部分 */
    .home_whiteboard {
      width: 92%;
      height: 64vh;
      border-radius: 0.64rem;
      background: #ffffff;
      margin: auto;
      margin-top: 34.8vh;
      overflow: hidden;
    }
  
    /* 标题部分 */
    .home_whiteboard_header {
      display: flex;
      justify-content: space-between;
      margin-top: 0.6133rem;
      margin-bottom: 0.5333rem;
  
      .home_whiteboard_header_title p {
        font-weight: Bold;
        color: #941302;
        font-size: 0.96rem;
      }
  
      .home_whiteboard_header_number p {
        font-weight: Bold;
        color: #999999;
        font-size: 0.64rem;
      }
    }
  
    .home_whiteboard_con {
      div {
        height: 2.6667rem;
        width: 100%;
        border-radius: 0.4267rem;
        margin-bottom: 1.0667rem;
        text-align: center;
        line-height: 2.6667rem;
      }
  
      /* 输入框部分 */
      input {
        background: none;
        outline: none;
        border: none;
        color: #999999;
        font-weight: bold;
        line-height: 2.6667rem;
        text-align: center;
        display: block;
        margin: auto;
      }
  
      .home_whiteboard_con_fake {
        border: 0.0267rem solid #acacac;
        background: #f5f5f5;
  
        input {
          font-size: 0.8533rem;
        }
      }
  
      .home_whiteboard_con_tel {
        border: 0.0267rem solid #acacac;
  
        input {
          font-size: 0.8rem;
        }
      }
  
      .home_whiteboard_con_tel_add {
        border: 0.0533rem solid #e93c2e;
        transition: 0.3s;
      }
  
      .home_whiteboard_con_code {
        display: flex;
        justify-content: space-between;
  
        .home_whiteboard_con_inputcode {
          width: 63% !important;
          border: 0.0267rem solid #999;
          font-weight: bold;
          color: #999999;
          font-size: 0.8rem;
        }
  
        .home_whiteboard_con_obtaincode {
          width: 35% !important;
          font-weight: bold;
          color: #f46d50;
          font-size: 0.8rem;
  
          button {
            padding: 0;
            margin: 0;
            border: none;
            background: none;
          }
        }
      }
    }
  
    .home_whiteboard_query {
      button {
        width: 8rem;
        height: 2.1333rem;
        display: block;
        margin: auto;
        margin-top: 2.6667rem;
        border-radius: 1.0667rem;
        border: none;
        background-color: #f46d50;
        font-size: 0.8533rem;
        font-weight: Bold;
        color: #ffffff;
        line-height: 2.1333rem;
        text-align: center;
      }
    }
  
    /* logo */
    .home_whiteboard_logo {
      margin-top: 1.4667rem;
      text-align: center;
  
      img {
        width: 2.1333rem;
        height: 2.1443rem;
      }
  
      p {
        margin-top: 0.1867rem;
        font-size: 0.8rem;
        color: #eebf63;
      }
    }
  
    /* banner */
    .home_whiteboard_banner {
      width: 84%;
      height: 6.4667rem;
      /* position: fixed;
         left: 50%;
         transform: translate(-50%);
         bottom: 2.5rem; */
      margin: auto;
      margin-top: 0.8rem;
      // margin-top: 1.6rem;
      background: #ffecec;
      border-radius: 0.7733rem;
  
      .banner-img {
        width: 100%;
        height: 100%;
        border-radius: 0.7733rem;
  
      }
    }
  
    /* 假用户警告 */
    .fakeAlert {
      .home_whiteboard_shadow {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0%;
        left: 0%;
        background-color: #000000;
        opacity: 0.8;
      }
  
      .home_whiteboard_fakeAlert {
        width: 15.68rem;
        height: 15.30rem;
        position: absolute;
        top: 11.7067rem;
        left: 50%;
        transform: translate(-50%);
        background-image: url("../assets/img/right_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        color: #666666;
        font-size: 0.92rem;
        line-height: 1.3984rem;
        text-align: center;
        padding-top: 8.73rem;
        .p{
          width: 9.46rem;
          font-size: 0.92rem;
          font-weight: 400;
          color: #666666;
          text-align: center;
          margin:0 auto 3.08rem;
        }
        p{
          font-size: 0.70rem;
          font-weight: 400;
          color: #999999;
        }
        img{
          width: 4.86rem;
          height: 4.86rem;
        }
      }
    }
  
    /* laoding加载页面 */
    .loading {
      width: 100%;
      height: 100vh;
      background: #666666;
      opacity: 0.5;
      position: fixed;
      top: 0%;
      z-index: 999;
  
      .loadingBox {
        width: 3rem;
        height: 3rem;
        margin: 50% auto;
      }
    }
  }
  </style>
  