import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import ResultView from '../views/Result.vue'
import DistributionView from '../views/Distribution.vue'
import Test from '../views/Test.vue'
import Active from '../views/Active.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/result',
    name: 'result',
    component: ResultView
  },
  {
    path: '/distribution',
    name: 'distribution',
    component: DistributionView
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
  {
    path: '/active',
    name: 'active',
    component: Active
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
})

export default router
