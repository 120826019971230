<template>
  <div>
    <div class="distribution_1">
      <img
        width="100%"
        height="100%"
        src="../assets/img/distribution_h5_01.png"
        alt=""
      />
      <div class="codeBox">
        <img width="100%" height="100%" :src="qrCodeImg" alt="" />
      </div>
    </div>
    <div class="distribution_2">
      <img
        width="100%"
        height="100%"
        src="../assets/img/distribution_h5_02.png"
        alt=""
      />
    </div>
    <div class="distribution_3">
      <img
        width="100%"
        height="100%"
        src="../assets/img/distribution_h5_03.png"
        alt=""
      />
    </div>
    <div class="distribution_4">
      <img
        width="100%"
        height="100%"
        src="../assets/img/distribution_h5_04.png"
        alt=""
      />
    </div>
    <div class="distribution_5">
      <img
        width="100%"
        height="100%"
        src="../assets/img/distribution_h5_05.png"
        alt=""
      />
    </div>
    <div class="distribution_6">
      <img
        width="100%"
        height="100%"
        src="../assets/img/distribution_h5_06.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Distribution",
  components: {},
  data() {
    return {
      qrCodeImg: "",
      gid: "",
    };
  },
  mounted() {
    this.getGid();
    this.getQrCodeImg();
  },
  methods: {
    async getGid() {
      let url = window.location.href;
      if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("=");
        // console.log("参数", strs[1]);
        this.gid = strs[1];
      }
    },
    async getQrCodeImg() {
      await this.$axios
        .get("https://manage.yoyolooming.com/api/template/verify/getXuejieById?gId=" + this.gid)
        .then((res) => {
          if (res.data.errorCode == 200) {
            this.qrCodeImg = res.data.data;
          }
        });
    },
  },
};
</script>

<style scoped>
.distribution_1 {
  position: relative;
}
.codeBox {
  width: 6.3rem;
  height: 6.3rem;
  position: absolute;
  bottom: 2.68rem;
  right: 1.68rem;
}
</style>