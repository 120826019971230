<template>
  <div>
    <div class="shouquan" @click="getLogin">授权</div>
  </div>
</template>

<script>
export default {
  name: "Test",
  data() {
    return {
      wxAppId: "wx6f103f7b5b4c187b",
      url: "https://m.yoyolooming.com", //重定向返回地址
      // url: "https://rank.yoyolooming.com/#/result", //重定向返回地址
      isWeixin: false,
    };
  },
  methods: {
    // 授权登录
    getLogin() {
      let str = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.wxAppId}&redirect_uri=${this.url}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
      window.location.href = str;
    },
    // 获取url参数
    urlParams(name) {
      let str = window.location.href;
      let params = str.substr(str.lastIndexOf("?") + 1).split("&");
      for (let i = 0; i < params.length; i++) {
        let param = params[i];
        let key = param.split("=")[0];
        let value = param.split("=")[1];
        if (key === name) {
          return value;
        }
      }
    },
    // 获取用户信息
    getUserInfo() {},
  },
  mounted() {
    // 判断是否在微信浏览器内
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf("micromessenger") !== -1; // 是否在微信浏览器内
    this.isWeixin = isWeixin;
    // 授权
    if (isWeixin && !this.urlParams("code")) {
      this.getLogin();
    }
    // 授权回来
    if (
      this.urlParams("code") && 
      localStorage.getItem("code") != this.urlParams("code")
    ) {
      this.getUserInfo(this.urlParams("code"));
    }
  },
};
</script>

<style lang="less">
.shouquan {
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
  margin: auto;
}
</style>
